<template>
  <div class="container col-start-start">
    <nav-bar headerTitle="退款进度" ref="head" :show-back="true" :header-back="newAppBack" />
    <div :class="['block col-center-center', clReimburseApplication ? '' : 'flex-auto']">
      <div class="box col-center-center">
        <img src="https://img.chaolu.com.cn/ACT/icon/time-icon.png" alt="" />
        <p class="p1 f32 fw6">{{ statusName }}</p>
        <p class="p2 f24" v-html="describeText"></p>
        <div v-if="!clReimburseApplication" @click="isApplyShow = true" class="apply-button f34 fw6 row-center-center">申请人工退款</div>
      </div>
    </div>
    <div v-if="clReimburseApplication" class="block col-center-center">
      <div class="form-box">
        <p class="title b-line f32 fw6">退款信息</p>
        <div class="content b-line">
          <p class="p1 row-between-center f28 fw6">
            <span class="s1">真实姓名</span>
            <span class="s2">{{ clReimburseApplication.realName }}</span>
          </p>
          <p class="p1 row-between-center f28 fw6">
            <span class="s1">开户行</span>
            <span class="s2">{{ clReimburseApplication.flowerBank }}</span>
          </p>
          <p class="p1 row-between-center f28 fw6">
            <span class="s1">银行卡号</span>
            <span class="s2">{{ clReimburseApplication.bankUsername }}</span>
          </p>
        </div>
        <div @click="cancel" class="cancel-button f28 fw6">{{ buttonName }}</div>
      </div>
    </div>

    <van-action-sheet v-model="isApplyShow" title="申请退款" @close="isApplyShow = false">
      <div class="form">
        <div class="form-item">
          <van-field v-model="params.realName" placeholder="请输入您的真实姓名" />
        </div>
        <div class="form-item">
          <van-field v-model="params.bankUsername" placeholder="请输入您的银行卡号" />
        </div>
        <div class="form-item row-start-center">
          <van-field v-model="params.flowerBank" placeholder="请输入您的开户行及开户行支行" />
        </div>
        <p class="p3 f24 row-start-center"><van-icon name="checked" color="#FDB928" size="16" />&nbsp;我已正确填写以上信息，确保本人可以收到退款。</p>
        <div @click="confirm" class="submit-button f34 fw6 row-center-center">确认提交</div>
      </div>

    </van-action-sheet>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import Regular from '@/lib/regular'
import navBar from '@/components/nav-bar-202210/index'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      isApplyShow: false,
      params: {
        flowerBank: '',
        bankUsername: '',
        realName: ''
      },
      clReimburseApplication: '',
    }
  },
  computed: {
    statusName() {
      if (!this.clReimburseApplication.realName) return '退款失败'
      if ([0, 4, 5].includes(this.clReimburseApplication.status)) return '退款失败'
      return '退款处理中'
    },
    describeText() {
      if (!this.clReimburseApplication) return '原支付账户异常，无法原路自动退回'
      if ([4].includes(this.clReimburseApplication.status)) return '您的退款信息有误，请检查修改后重新申请'
      return '您的退款已受理，超鹿会尽快完成审核<br />请耐心等待，感谢您的理解与支持'
    },
    buttonName() {
      if ([4].includes(this.clReimburseApplication.status)) return '重新申请'
      return '取消申请'
    },
  },
  async created() {
    await this.$getAllInfo(['userId'])
    initBack()

    const q = getParam().clReimburseApplication
    console.log(JSON.parse(decodeURIComponent(q)), 'jjjjj');

    if (q) this.clReimburseApplication = JSON.parse(decodeURIComponent(q))

  },
  methods: {
    newAppBack,
    confirm() {
      if (!this.params.realName) {
        this.$toast('请输入真实姓名')
        return
      }
      if (!Regular.isBank(this.params.bankUsername)) {
        this.$toast('请输入正确的银行卡号')
        return
      }
      if (!this.params.flowerBank) {
        this.$toast('请输入您的开户行')
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true
      })
      this.$axios.post(`${this.baseURLApp}/reimburse/writeInfo/v1`, {...this.params, userId: this.userId, token: this.token, isRepeat: 1}).then(res => {
        this.isApplyShow = false
        this.$toast('已提交申请！')
        setTimeout(() => {
          this.newAppBack()
        }, 2000);
      }).catch(r => {

      })
    },
    cancel() {
      if (this.clReimburseApplication.status === 4) {
        this.isApplyShow = true
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true
      })
      this.$axios.post(`${this.baseURLApp}/reimburse/updateStatus/v1`, {userId: this.userId, token: this.token, status: 5, applicationId: this.clReimburseApplication.applicationId }).then(res => {
        this.$toast('已取消！')
        setTimeout(() => {
          this.newAppBack()
        }, 2000);
      }).catch(r => {
        this.$toast.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: #eeeeee;
  min-height: 90vh;
  padding: 0 0 calc(env(safe-area-inset-bottom));
  * {
    box-sizing: border-box;
  }
  .block {
    width: 100%;
    background: #fff;
    padding: 0 30px;
    & + .block {
      margin: 20px 0 0;
    }
    .box {
      padding: 100px 0;
      .apply-button {
        width: 500px;
        height: 88px;
        background: #FDB928;
        border-radius: 44px;
        color: #Fff;
        margin: 92px 0 0;
      }
      img {
        width: 164px;
      }
      .p1 {
        margin: 22px 0 0;
      }
      .p2 {
        color: #999999;
        margin: 20px 0 0;
        text-align: center;
      }
    }
    .b-line {
      border-bottom: 1px solid #eeeeee;
    }
    .form-box {
      width: 100%;
      .title {
        padding: 28px 0;
      }
      .content {
        padding: 16px 0;
      }
      .p1 {
        padding: 16px 0;
      }
      .s1 {
        color: #999999;
      }
      .s2 {
        text-align: right;
      }
      .cancel-button {
        padding: 28px 0;
        color: #f66e60;
        text-align: center;
      }
    }
  }
  .form {
    padding: 24px 30px;
    .p3 {
      margin: 40px 0 0;
    }
    .form-item {
      width: 100%;
      height: 88px;
      background: #FAFAFA;
      border-radius: 44px;
      border: 1px solid #EEEEEE;
      margin: 0 0 30px;
      .van-cell {
        background-color: transparent;
      }
    }
    .submit-button {
      width: 100%;
      height: 90px;
      background: #FDB928;
      color: #fff;
      border-radius: 45px;
      margin: 30px 0 0;
    }
  }
}
</style>
